import image1 from './assets/school staff/principals.jpg';
import image2 from './assets/school staff/year1-mandarin.jpg';
import image3 from './assets/school staff/year2-mandarin.jpg';
import image4 from './assets/school staff/year3-mandarin.jpg';
import image5 from './assets/school staff/year4-mandarin.jpg';
import image6 from './assets/school staff/year5-mandarin.jpg';
import image7 from './assets/school staff/year6-mandarin.jpg';
import image8 from './assets/school staff/year78-mandarin.jpg';
import image9 from './assets/school staff/gcse.jpg';
import image10 from './assets/school staff/alevel.jpg';
import image11 from './assets/school staff/cantonese.jpg';
import image12 from './assets/school staff/speaking.jpg';

export default [image1,image2,image3,image4,image5,image6,image7,image8,image9,image10,image11,image12];