import React from 'react';

import './HiringPage.css';

function HiringPage() {
  return (
    <div className='hiring-page-container'>
      <h1>北爱尔兰中文学校招聘启事</h1>
      <br />
      <p>
        因学校发展需要，2024-2025学年北爱中文学校拟向社会公开招聘课堂班主任老师、助教老师及代课老师若干。
      </p>
      <br />
      <h2>一、岗位职责</h2>
      <p>
        &#x2022;
        课堂班主任及代课老师（普通话&粤语）：能独立教授中文课程、作业批改以及向家长反馈学生情况，负责组织协调课前课后学生管理。
      </p>
      <p>
        &#x2022;
        助教老师：配合班主任老师维持课堂纪律，进行课堂活动的监督以及组织学生有序地进行课上活动。
      </p>
      <br />
      <h2>二、招聘条件</h2>
      <p>
        热爱华文教育，有育人情怀，责任心强，有较强的时间管理能力和团队合作意识。
      </p>
      <br />
      <h2>三、岗位要求</h2>
      <h3>1. 课堂班主任及代课老师：</h3>
      <p>&#x2022; 具有端正的教育教学理念，有育人的情怀。</p>
      <p>&#x2022; 热爱汉语教学（普通话&粤语）以及中华传统文化传播。</p>
      <p>&#x2022; 具备一定的教育教学经验或语言学习及教学或文化交流背景经历。</p>
      <p>&#x2022; 具备良好的语言表达和沟通能力，具有优秀的中文写作能力。</p>
      <p>
        &#x2022;
        具有本科以上的学历、有汉语国际教育或中文教育、教育学等相关学历优先。
      </p>
      <p>
        &#x2022;
        具有各类教师资格证书者优先（包括国内外教师资格证、华文教师资格证等）。
      </p>
      <h3>2. 助教老师：</h3>
      <p>&#x2022; 具有处理突发事件的应急反应能力。</p>
      <p>&#x2022; 具有一定的组织协调能力和团队意识。</p>
      <p>&#x2022; 具有一定的教学管理经验和能力优先。</p>
      <br />
      <h2>四、报名方式</h2>
      <p>
        即日起即可报名，扫描网页下方二维码添加学校微信发送个人简历，或发送简历至邮箱：chinese.school.ni@gmail.com。
      </p>
    </div>
  );
}

export default HiringPage;
